import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import ExcerptRoll from '../components/ExcerptRoll'
import TopBanner from '../components/TopBanner'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPost(sort: { fields: date, order: DESC }, limit: 10) {
          edges {
            node {
              id
              title
              excerpt
              slug
              featured_media {
                source_url
              }
              author {
                name
              }
              date
            }
          }
        }
      }
    `}
    render={() => (
      <Layout>
        <TopBanner />
        <ExcerptRoll />
      </Layout>
    )}
  />
)
export default IndexPage
