import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img from 'gatsby-image'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(filter: { slug: { eq: "index" } }) {
          edges {
            node {
              id
              slug
              acf {
                top_banner_socials {
                  social_network_title
                  social_network_url
                  social_network_icon
                }
                section_top_banner_title
                section_top_banner_tagline
                section_top_banner_image {
                  source_url
                  title
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth:508) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const pageData = data.allWordpressPage.edges[0].node

      return (
        <div className="top-banner">
          <div className="grid-container">
            <section className="grid-x grid-margin-x">
              <div className="cell large-6 align-self-middle">
                <h1>{pageData.acf.section_top_banner_title}</h1>
                <p className="banner-subtitle">
                  {pageData.acf.section_top_banner_tagline}
                </p>
                {pageData.acf.top_banner_socials.map(social => (
                  <a
                    href={social.social_network_url}
                    key={social.social_network_title}
                    className="social-icon"
                    aria-label={`Click to see more about Efficiency of Movement on ${social.social_network_title}`}
                  >
                    <FontAwesomeIcon
                      icon={['fab', social.social_network_icon]}
                    />
                  </a>
                ))}
              </div>

              <div className="cell large-6 top-banner-image-holder">
                <Img
                  alt={pageData.acf.section_top_banner_image.alt_text}
                  fluid={
                    pageData.acf.section_top_banner_image.localFile.childImageSharp.fluid
                  }
                />
              </div>
            </section>
          </div>
        </div>
      )
    }}
  />
)
