import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img from 'gatsby-image'

function pad(n) {
  return n < 10 ? `0${n}` : n
}

export default () => (
  <StaticQuery
    query={graphql`
      query ExcerptRollQuery {
        allWordpressPost(sort: { fields: date, order: DESC }, limit: 3) {
          edges {
            node {
              featured_media {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 260) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              id
              better_featured_image {
                alt_text
                media_details {
                  sizes {
                    large {
                      source_url
                    }
                  }
                }
              }
              title
              excerpt
              slug
              author {
                name
              }
              date(formatString: "MMMM DD, YYYY")
            }
          }
        }
      }
    `}
    render={data => (
      <div className="grid-container excerpt-roll">
        <section className="grid-x grid-margin-x posts-list">
          {data.allWordpressPost.edges.map((post, index) => (
            <div
              key={post.node.id}
              className="cell medium-10 medium-offset-1 posts-list-post"
            >
              <div className="card">
                <div className="grid-x">
                  <div className="cell medium-4 card-section text-center card-image">
                    <Img
                      alt={post.node.featured_media.alt_text}
                      fluid={
                        post.node.featured_media.localFile.childImageSharp.fluid
                      }
                    />
                  </div>
                  <div className="cell medium-8 card-section card-copy">
                    <div className="card-divider card-title">
                      <h4 className="excerpt-title">{post.node.title}</h4>
                    </div>
                    <div className="card-section card-body">
                      {ReactHtmlParser(post.node.excerpt)}
                    </div>
                    <div className="card-section grid-x card-meta">
                      <span className="cell large-8 text-center post-meta">
                        <span className="grid-x">
                          <span className="author-meta">
                            <span className="icon-holder">
                              <FontAwesomeIcon icon={['fa', 'user']} />
                            </span>
                            {'By ' + post.node.author.name}
                          </span>
                          {/*<span className="date-meta">*/}
                          {/*  <span className="icon-holder">*/}
                          {/*    <FontAwesomeIcon icon={['fa', 'calendar-alt']} />*/}
                          {/*  </span>*/}
                          {/*  {post.node.date}*/}
                          {/*</span>*/}
                        </span>
                      </span>
                      <span className="cell large-4 large-text-right read-more">
                        <Link to={`posts/${post.node.slug}`} className="button">
                          Read More
                          <span className="icon-holder">
                            <FontAwesomeIcon icon={['fa', 'plus']} size="xs" />
                          </span>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="post-date">
                  <span>{post.node.date}</span>
                </div>
              </div>
            </div>
          ))}
          <Link className="button expanded large" to="/posts/2">
            More Posts
          </Link>
        </section>
      </div>
    )}
  />
)
